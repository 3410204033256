<template>
<SolutionsBody />
</template>

<script>
import SolutionsBody from "@/components/Solutions/SolutionsBody";

export default {
  name: "Solutions",
  components: {SolutionsBody}
}
</script>

<style scoped>

</style>