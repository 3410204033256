<template>
  <section id="ref" class="container py-5">
    <p class="display-4 m-head">Güvenlik Referanslarımız:</p>
    <template v-for="(item, index) in guvenlik_referanslar">
      <details :key="index">
        <summary>
          {{ item.baslik }}
        </summary>
        <table class="table">
          <tbody>
            <template v-for="(inner_item, index) in item.datas">
              <tr :key="index">
                <td>{{inner_item.isim}}</td>
                <td>{{inner_item.yer}}</td>
                <td>{{inner_item.yöntem}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </details>
    </template>
  </section>
</template>

<script>
import guvenlik_referanslar from "./guvenlik-ref.js";
export default {
  // v-for="(item, index) in this.elektrik_referanslar"
  name: "ReferansGuvenlik",
  data() {
    return {
      guvenlik_referanslar,
    };
  },
};
</script>

<style lang="scss" scoped>
#ref {
  .m-head {
    color: rgba($color: #164098, $alpha: 0.85) !important;
  }
  details {
    padding: 20px;
    table {
      color: rgba($color: #000000, $alpha: 0.65) !important;
    }

    summary {
      color: rgba($color: #164098, $alpha: 0.7) !important;
      font-size: 1.6rem;
      &:focus {
        outline: none;
      }
      &::-webkit-details-marker {
        background: url("./images/expand.svg") center no-repeat;
        color: transparent;
        width: 17px;
        height: 17px;
      }
    }
  }
  details[open] summary::-webkit-details-marker {
    background: url("./images/collapse.svg") center no-repeat;
    color: transparent;
    width: 17px;
    height: 17px;
  }
  details[open] summary ~ * {
    animation: sweep 0.5s ease-in-out;
  }
  @keyframes sweep {
    from {
      opacity: 0;
      margin-top: -10px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
}
</style>
