<template>
  <div class="referancee">
    <ReferansElectric/>
  </div>
</template>

<script>
// @ is an alias to /src
import ReferansElectric from '@/components/ReferansTemplate/ReferansGuvenlik.vue'

export default {
  name: 'Home',
  components:{
    ReferansElectric
  }
}
</script>