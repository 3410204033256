<template>
  <div id="ref__gallery">
    <div class="container-fluid">
      <div class="row">
        <div class="column">
          <img
            src="./images/20161025_005338.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/20161025_005347.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_4206.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_1375.jpg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="column">
          <img
            src="./images/IMG_4379.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_4421.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_6009.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_8491.jpg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="column">
          <img
            src="./images/20161025_005722.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_1889.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_1896.jpg"
            alt=""
            loading="lazy"
          />
          <img
            src="./images/IMG_1919.jpg"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'ReferansGallery'
};
</script>

<style lang="scss" scoped>
.row{
    display:flex;
    flex-wrap: wrap;
    .column{
        flex: 33.3%;
        width: auto;
        overflow: hidden;
        padding: 0 4px;
        height: 100%;
        img{
            margin-top: 2%;
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
        
    }
    @media only screen and (max-width:600px) {
        .column{
            flex: 50%;
        }
    }
    @media only screen and (max-width:400px) {
        .column{
            flex: 100%;
        }
    }
}
</style>
