<template>
  <ReferansGallery/>
</template>

<script>
import ReferansGallery from '@/components/ReferansTemplate/ReferansGallery/ReferansGallery.vue'
export default {
  components: { ReferansGallery },

}
</script>

<style>

</style>