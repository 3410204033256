<template>
  <div>
    <!--<div id="sec" class="sec-div py-5">
      <div class="inner">
        <div class="column-f p-3">
          <div class="media">
            <div class="media-body">
              <h5 class="mt-0 mb-1">CCTV</h5>
            </div>
            <img
              class="ml-3"
              src="./images/security-camera.svg"
              width="100px"
            />
          </div>
        </div>
        <div class="column-f p-3">
          <div class="media">
            <div class="media-body">
              <h5 class="mt-0 mb-1">Yangın Alarm-Müdahale Sistemleri</h5>
            </div>
            <img class="ml-3" src="./images/fire.png" width="100px" />
          </div>
        </div>
        <div class="column-f p-3">
          <div class="media">
            <div class="media-body">
              <h5 class="mt-0 mb-1">Akıllı Geçiş Sistemleri</h5>
            </div>
            <img class="ml-3" src="./images/dial-keypad.svg" width="100px" />
          </div>
        </div>
        <div class="column-f p-3">
          <div class="media">
            <div class="media-body">
              <h5 class="mt-0 mb-1">Hırsız Alarm Sistemleri</h5>
            </div>
            <img class="ml-3" src="./images/siren.svg" width="100px" />
          </div>
        </div>
        <div class="column-f p-3">
          <div class="media">
            <div class="media-body">
              <h5 class="mt-0 mb-1">Ortam İzleme Sistemleri</h5>
            </div>
            <img class="ml-3" src="./images/monitor.svg" width="100px" />
          </div>
        </div>
        <div class="column-f p-3">
          <div class="media">
            <div class="media-body">
              <h5 class="mt-0 mb-1">Network Sistemleri</h5>
            </div>
            <img class="ml-3" src="./images/server.svg" width="100px" />
          </div>
        </div>
        <div class="column-f p-3">
          <div class="media">
            <div class="media-body">
              <h5 class="mt-0 mb-1">Akıllı Bina Sistemleri</h5>
            </div>
            <img class="ml-3" src="./images/smart-building.png" width="100px" />
          </div>
        </div>
      </div>
    </div>-->
    <div id="elec" class="elec-div py-5">
      <div class="inner">
        <div class="column-f container p-3">
          <div class="media">
            <img class="ml-3" src="./images/toprak.svg" width="100px" />
            <div class="media-body pl-3">
              <h5 class="mt-0 my-3">Elektriksel Ölçüm ve Raporlama Hizmeti</h5>
              <article>
                <p>6331 Sayılı İş Sağlığı ve Güvenliği yasası gereğince;</p>

                <p>İşyerlerinde:</p>
                <p>Topraklama Ölçümleri -Kontrolü ve Raporlanması</p>
                <p>Termal Kamera Ölçümü ve Raporlanması</p>
                <p>Elektrik İç Tesisat Uygunluk Kontrolü ve Raporlanması</p>
                <p>Enerji kalitesi , Harmonik Ölçümleri ve Raporlanması</p>
                <p>Toroid ve Kaçak Akım Rölesi Testleri</p>
                <p>İzolasyon testleri ve raporlanması.</p>
                <p>Trafo ve Jeneratör peryodik kontrolleri ve Raporlanması.</p>
                <p>
                  Tüm ölçümler yetkili Elektrik mühendislerimiz tarafından
                  TURKAK sertifikalı cihazlar ile yapılmaktadır.
                </p>
              </article>
            </div>
          </div>
          <div class="mini-gallery">
            <img class="rounded shadow-lg" src="./mini-gallery/e-olcum-1.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/e-olcum-2.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/e-olcum-3.jpg" alt="" />
          </div>
        </div>
        <div class="column-f container p-3">
          <div class="media">
            <img class="ml-3" src="./images/tesisat.svg" width="100px" />
            <div class="media-body pl-3">
              <h5 class="mt-0 my-3">Elektrik Taahhüt</h5>

              <article>
                <p>-Fabrikalar ve Sanayi Tesisleri</p>
                <p>-Nitelikli konut projeleri</p>
                <p>-Üniversite binaları</p>
                <p>-Data Center ve Bilgi işlem merkezleri</p>
                <p>-Banka Şubeleri</p>
                <p>-Oteller</p>
                <p>-İş ve Yaşam merkezleri</p>
                <p>
                  -Hastaneler vb. binaların elektriksel alt yapı ve üst yapı
                  işlerinin yapılması.
                </p>
              </article>
            </div>
          </div>
          <div class="mini-gallery">
            <img class="rounded shadow-lg" src="./mini-gallery/tahhut-2.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/tahhut-1.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/tahhut-3.jpg" alt="" />
          </div>
        </div>
        <div class="column-f container p-3">
          <div class="media">
            <img class="ml-3" src="./images/project.svg" width="100px" />
            <div class="media-body pl-3">
              <h5 class="mt-0 my-3">
                Proje Çizim - Danışmanlık - Kontrollük Hizmetleri
              </h5>
              <article>
                <p>Her türlü yapıların:</p>
                <p>
                  Kuvvetli akım - Zayıf akım ve Orta gelirim Elektrik Uygulama
                  projelerinin ve iş bitiminde son durum projelerinin
                  hazırlanması
                </p>
                <p>
                  Ön keşif proje ve fizibilite aşamalarında danışmanlık
                  hizmetlerinin verilmesi ve Teknik şartnamelerin hazırlanması
                </p>
                <p>
                  Yapımı devam eden işlerin Elektrik ve ilgili tüm
                  yönetmeliklere göre kontrolünün sağlanması
                </p>
                <p>
                  Kesin hesapların hazırlanması ve kabul işlemlerinin yapılması.
                </p>
              </article>
            </div>
          </div>
          <div class="mini-gallery">
            <img class="rounded shadow-lg" src="./mini-gallery/proje-1.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/proje-2.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/proje-3.jpg" alt="" />
          </div>
        </div>
        <div class="column-f container p-3">
          <div class="media">
            <img class="ml-3" src="./images/isg.png" width="100px" />
            <div class="media-body pl-3">
              <h5 class="mt-0 my-3">İş Sağlığı ve Güvenliği</h5>
              <article>
                <p>
                  İşyerlerinde yapılan çalışmalarda kullanılan makine ve
                  teçhizatın seçimi, kullanım sırasında dikkat edilmesi gereken
                  kuralların belirlenmesi, işin planlanarak uygulanması,
                  uygulanırken iş yürütümüyle alakalı olan kişisel koruyucu
                  donanımların belirlenerek seçilmesi, temini ve kullanımının
                  denetlenmesi. İşyerinde meydana gelen iş kazası ve meslek
                  hastalıklarının nedenlerinin araştırılması ve tekrarlanmaması
                  için alınacak önlemler konusunda çalışmalar yaparak işverene
                  önerilerde bulunmak. İş sağlığı ve güvenliği yönünden risk
                  değerlendirmesi yapılmasıyla ilgili çalışmalara ve
                  uygulanmasına katılmak, risk değerlendirmesi sonucunda
                  alınması gereken sağlık ve güvenlik önlemleri konusunda
                  işverene önerilerde bulunmak ve takibini yapmak. Çalışma
                  ortamının gözetiminin yapılması, işyerinde iş sağlığı ve
                  güvenliği mevzuatı gereği yapılması gereken periyodik bakım,
                  kontrol ve ölçümleri planlamak ve uygulamalarını kontrol
                  etmek. İşyerinde doğal afet, kaza, yangın, patlama ve salgın
                  hastalık gibi durumlar için acil durum planlarının
                  hazırlanması çalışmalarına katılmak, bu konuyla ilgili
                  periyodik eğitimlerin ve tatbikatların yapılmasını ve acil
                  durum planı doğrultusunda hareket edilmesini izlemek ve
                  kontrol etmek. Tüm çalışmalar -A- Sınıfı İŞ güvenliği
                  Uzmanlarımız tarafından yapılmaktadır.
                </p>
              </article>
            </div>
          </div>
          <div class="mini-gallery">
            <img class="rounded shadow-lg" src="./mini-gallery/safety-1.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/safety-2.jpg" alt="" />
            <img class="rounded shadow-lg" src="./mini-gallery/safety-3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionsBody",
  methods: {},
  mounted: function () {
    //Routerdan section ve id geliyor daha sonra istediğine eğer varsa o solutiona gidiyor;
    this.$nextTick(() => {
      let router_handler = (section, id) => {
        let requested_element = section.children[id];
        requested_element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          
        });
        console.log(requested_element);
      };
      if (this.$route.params.id) {
        if (this.$route.params.section) {
          //let elec = "elec";
          //let sec = "sec";
          /*switch (this.$route.params.section) {
            case elec: {
              document.getElementById(sec).classList.add("d-none");
              break;
            }
            case sec: {
              document.getElementById(elec).classList.add("d-none");
              break;
            }
            default: {
              break;
            }
          }*/
        }
        let section = document.getElementById(this.$route.params.section).firstChild;
        router_handler(section, this.$route.params.id);
      }
    });
  },
};
</script>


<style lang="scss" scoped>
.inner {
  display: grid;
  width: 100%;
  .column-f {
    h5 {
      color: rgba($color: #000, $alpha: 0.8) !important;
    }
    color: rgba($color: #000, $alpha: 0.6);
    width: 100%;
  }
  .mini-gallery{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    img{
      margin:20px;
      width:310px;
    }
  }
}
</style>