const elektrik_referanslar = [
  {
    baslik:"Plaza - İş Merkezi",
    datas:[
      {
        "isim": "MV HOLDİNG A.Ş.",
        "yer": "Kozyatağı / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK PLAZA A.Ş.",
        "yer": "Kozyatağı / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK LOJİSTİK DEPO",
        "yer": "Kayışdağı / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "PALME MAKİNA A.Ş.",
        "yer": "Dudullu GM / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "POLİTEST A.Ş.",
        "yer": "Ataşehir / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "HAVA İŞ SENDİKASI GENEL MÜDÜRLÜK BİNASI .",
        "yer": "Bakırköy/ İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ESKİDJİ ÇARŞI YÖNETİMİ",
        "yer": "İSTANBUL",
        "yöntem": "Uygulama"
      },
      {
        "isim": "Pendik , Haramidere, Sancaktepe AVM topraklama ve paratoner işleri",
        "yer": "",
        "yöntem": ""
      }
     ]
  },
  {
    baslik:"Banka - Finans",
    datas:[
      {
        "isim": "DENİZBANK A.Ş.",
        "yer": "Taşdelen/ İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DENİZBANK A.Ş.",
        "yer": "Çekmeköy/ İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DENİZBANK A.Ş.",
        "yer": "Altıntepe/ İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DENİZBANK A.Ş.",
        "yer": "İzmit/ Kocaeli",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DENİZBANK A.Ş.",
        "yer": "Hendek / Sakarya",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DENİZBANK A.Ş.",
        "yer": "Caddebostan/ İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "İŞBANKASI T.A.Ş.",
        "yer": "DES dudullu / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "İŞBANKASI T.A.Ş.",
        "yer": "Göktürk / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "Garanti Bankası Ulus Sanat Galerisi",
        "yer": "Ulus /Ankara",
        "yöntem": "Uygulama"
      },
      {
        "isim": "ANADOLUBANK",
        "yer": "Küçükesat / Ankara",
        "yöntem": "Uygulama"
      },
      {
        "isim": "TÜRK EKONOMİ BANKASI A.Ş.",
        "yer": "Bakırköy / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TÜRK EKONOMİ BANKASI A.Ş.",
        "yer": "Şirinevler / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TÜRK EKONOMİ BANKASI A.Ş.",
        "yer": "Bayrampaşa / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TÜRK EKONOMİ BANKASI A.Ş.",
        "yer": "Gültepe / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TÜRK EKONOMİ BANKASI A.Ş.",
        "yer": "İkitelli / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TÜRK EKONOMİ BANKASI A.Ş.",
        "yer": "Sarıyer / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "AKBANK KREDİLER MÜDÜRLÜĞÜ",
        "yer": "Bakırköy / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "ING BANK A.Ş. ETİLER ŞB.",
        "yer": "Etiler / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "HSBC BANK SUADİYE ŞUBESİ",
        "yer": "Suadiye/İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "HSBC BANK B BLOK BİLGİ İŞLEM MERKEZİ",
        "yer": "Esentepe/İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "HSBC BANK PROFİLO ŞUBESİ",
        "yer": "Mecidiyeköy/İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "ALFA MENKUL KIYMETLER A.Ş. OFİS BÜROSU",
        "yer": "Akatlar/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "COMMERCIAL UNION SIGORTA A.Ş. BÖLGE MÜDÜRLÜĞÜ",
        "yer": "Buttim/Bursa",
        "yöntem": "Proje + Uygulama"
      }
     ]
  },
  {
    baslik:"Mağazacılık",
    datas:[
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Angora /Antalya ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Sümerpark/Denizli",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Kızılay / Ankara",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Çubuk/Ankara",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Etimesgut / Ankara",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Erciş/Van",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Batmanpark/Batman",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Urfa Piazza/Urfa",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "39Burda /Kırklarleri ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "67 Burda/Zonguldak ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Canpark/İstanbul",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Meydavn AVM/İstanbul",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "GENCALLAR MAĞAZACILIK",
        "yer": "Atlaspark /İstanbul",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "39Burda /Kırklarleri ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "GENCALLAR MAĞAZACILIK",
        "yer": "MNG  AVM / Erzurum ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "GENCALLAR MAĞAZACILIK",
        "yer": "39Burda /Kırklarleri ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Watergarden / İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Ümraniye Meydan AVM",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Starcity/İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Novada AVM/Tokat ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Sivas Meydan ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Point AVM/İzmir ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Pashadoor/İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Optimum AVM/İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Tarsu AVM /Mersin ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Mezitli Cadde/ Mersin ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Manisa Cadde",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Kars  Meydan ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Westpark İzmir ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Torbalı İzmir ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "İnegöl Avm ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Historia AVM /İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Dörtyol Hatay  ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Haliç AVM /İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Giresun Cadde ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Elazığ Cadde ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Park23/Elazığ ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "FSM cd/ Bursa",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Brandium AVM /İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Midtown/Bodrum ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Bergama İzmir ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Bakırköy İstanbul",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Avlu AVM /İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Armonipak /İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Kartaltepe AVM Ankara ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "212AVM İstanbul ",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "10Burda AVM Balıkesir",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "Hilltown/istanbul",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "Vadiistanbul/İst",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "MaltepePİazza /İST",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "Capitol / İstanbul",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "DOROMCO",
        "yer": "Vadiistanbul/İst",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "DOROMCO",
        "yer": "Malloff /İstanbul",
        "yöntem": "Proje+kontrollük "
      },
      {
        "isim": "BOYNER MAĞAZACILIK",
        "yer": "Capitol/İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "BOYNER BEAUTE",
        "yer": "Galeria / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "YKM MAĞAZASI ( 10 katlı)",
        "yer": "Denizli",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "İstinyepark / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Suadiye / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER GENEL MÜDÜRLÜĞÜ",
        "yer": "Kozyatağı/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Capitol/ İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER ÇOCUK MAĞAZASI",
        "yer": "Flyİnn/İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Gima /Antalya",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Akmerkez /İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Flyİnn/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "AS Plaza/Bursa",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Nişantaşı / İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Oasis / Bodrum",
        "yöntem": "Uygulama"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Kozyatağı GM / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "THCIBO GENEL MÜDÜRLÜK OFİSİ",
        "yer": "Bay Plaza / Kozyatağı",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "THCIBO MAĞAZASI",
        "yer": "Capitol / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "THCIBO MAĞAZASI",
        "yer": "Carrefoursa/ Ümraniye",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "MOR DEKORASYON",
        "yer": "Kavacık/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ZARA TEKSTİL GENEL MÜDÜRLÜK OFİSİ.",
        "yer": "İşkule / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Denizli",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Trabzon",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Palladium/ İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Agora/İzmir",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Tekirdağ",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Pendik/istanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Güneşli / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Sefaköy/ İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Acity / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Denizli",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "İstinyepark / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Güneşli / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Ada Center/ Sakarya",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Bağcılar/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Sefaköy/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Taksim/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Topkapı/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Carrefoursa/Maltepe",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Akmerkez/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Carusel/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Cevahir İŞ Merkezi",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BATA MAĞAZASI",
        "yer": "Cevahir İŞ Merkezi",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK TURKCELL SHOP",
        "yer": "Cevahir İş Merkezi",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK TURKCELL SHOP",
        "yer": "Akvaryum İş Merkezi",
        "yöntem": "Proje + Uygulama"
      }
     ]
  },
  {
    baslik:"Hastane - Sağlık",
    datas:[
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ  ONKOLOJİ BİNASI",
        "yer": "Ümraniye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ TOPRAKLAMA ÖLÇÜMLERİ",
        "yer": "Ümraniye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ TR1-TR2 GÜÇ ANALİZİ",
        "yer": "Ümraniye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ALEMDAĞ DEVLET HASTANESİ",
        "yer": "Alemdağ / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÇAKMAK SEMT POLİKLİNİĞİ",
        "yer": "Çakmak / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "M.KEMAL SEMT POLİKLİNİĞİ",
        "yer": "M.Kemal / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ ACİL SERVİS",
        "yer": "Ümraniye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ KRORONER YOĞUN BAKIM",
        "yer": "Ümranye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "İLSAĞLIK MÜDÜRLÜĞÜ VEREM SAVAŞ DİSPANSERİ",
        "yer": "Fatih / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "İLSAĞLIK MÜDÜRLÜĞÜ VEREM SAVAŞ DİSPANSERİ",
        "yer": "Esenyurt / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "İLSAĞLIK MÜDÜRLÜĞÜ VEREM SAVAŞ DİSPANSERİ",
        "yer": "Kağıthane / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "İLSAĞLIK MÜDÜRLÜĞÜ VEREM SAVAŞ DİSPANSERİ",
        "yer": "Bayrampaşa / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "İLSAĞLIK MÜDÜRLÜĞÜ VEREM SAVAŞ DİSPANSERİ",
        "yer": "Esenyurt Y.kent / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ YENİDOĞAN YOĞUNBAKIM",
        "yer": "Ümranye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "FİZİKMER FİZİK TEDAVİ MERKEZİ",
        "yer": "Gebze/İzmit",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE GÖZ HASTANESİ ELEKTRİK PROJELERİ",
        "yer": "Ümraniye / İstanbul",
        "yöntem": "Proje"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ DİYALİZ YOĞUNBAKIM",
        "yer": "Ümranye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANESİ  120 KVA KURULUM",
        "yer": "Ümranye / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "ÜMRANİYE ARAŞTIRMA HASTANSİ ANJİYO KLİNİĞİ ",
        "yer": "",
        "yöntem": ""
      }
     ]
  },
  {
    baslik:"Market",
    datas:[
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Uğurmumcu / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Bostancı Dörtyol / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "K.Yalı inceyol / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Maltepe Sağlık / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Maltepe Doğuşkent / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Bostancı Yalıyolu / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Karlıktepe-Kartal / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Orhangazi-Maltepe / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Esentepe-Kartal / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Maltepe / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Başak-Küçükyalı / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "İnönü-Kartal / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Maltepe sahil / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Karayolları Maltepe / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Aydınevler-Maltepe / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Yüzevler-Maltepe / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Gülsuyu / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "Ankara bölgeye bağlı 160 mağazanın anlaşmalı bakım onarım işleri ve mağaza tadilatları 4 YIL",
        "yer": "",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Senatoryum / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Başak / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Selçuklu / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Mamak / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Türközü / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Aydınlıkevler / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Etimesgut / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Topçu Mah. / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "Hızırtepe / Sakarya",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "BİM BİRLEŞİK MAĞAZALAR A.Ş",
        "yer": "UFUKTEPE / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "MACRO CENTER MAĞAZASI",
        "yer": "Kuruçeşme/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TANSAŞ GENEL MÜD.İLAVE OFİS KATLARI",
        "yer": "Dudullu/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TANSAŞ MAĞAZASI",
        "yer": "Yeniköy/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "MAKRO CENTER MAĞAZASI",
        "yer": "Akmerkez/İstanbul",
        "yöntem": "Proje + Uygulama"
      }
     ]
  },
  {
    baslik:"Teknik Servisler",
    datas:[
      {
        "isim": "KVK TEKNİK SERVİS A.Ş.",
        "yer": "Kızılay / Ankara",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK TEKNİK SERVİS A.Ş.",
        "yer": "Adana",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK TEKNİK SERVİS A.Ş.",
        "yer": "Diyarbakır",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK TEKNİK SERVİS A.Ş.",
        "yer": "Bursa",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK TEKNİK SERVİS A.Ş.",
        "yer": "Beşiktaş / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KVK TEKNİK SERVİS A.Ş.",
        "yer": "Kozyatağı / İstanbul",
        "yöntem": "Proje + Uygulama"
      }
     ]
  },
  {
    baslik:"Üniversite - Eğitim",
    datas:[
      {
        "isim": "T.C. SAKARYA ÜNİVERSİTESİ 77 ADET UPS KURLUMU",
        "yer": "Sakarya",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TC SAKARYA ÜNİVERSİTESİ DATA CENTER ( Sistem Odası) ",
        "yer": "Sakarya",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TC BİLECİK ÜNİVERSİTESİ DATA CENTER ( Sistem Odası) ",
        "yer": "Bilecik",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "TC SAKARYA ÜNİVERSİTESİ KONFERANS SALONU",
        "yer": "Sakarya",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "WALL STREET INSTITUTE (PEN ACADEMY)",
        "yer": "Kızılay/Ankara",
        "yöntem": "Proje + Uygulama"
      }
     ]

  },
  {
    baslik:"Otomotiv - Servis",
    datas:[
      {
        "isim": "REM MAKİNA A.Ş. ( ERPAŞ san. Sit )",
        "yer": "Maltepe / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KİA MOTORS MERKEZ SERVİSİ",
        "yer": "Kartal/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "GÜL AL OTO SHOWROOM VE SERVİS",
        "yer": "Ümraniye/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "KIA ÇETAŞ SHOWROOM",
        "yer": "Güneşli/İstanbul",
        "yöntem": "Proje + Uygulama"
      }
     ]
  },
  {
    baslik:"Diğer İşler",
    datas:[
      {
        "isim": "YKK HUKUK BÜROSU",
        "yer": "Astoria / İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "Procter & Gamble (P & G ) proje işleri ",
        "yer": "Gebze",
        "yöntem": "Proje"
      },
      {
        "isim": "KOPPERT ARI ÜRETİM TESİSLERİ",
        "yer": "Organize / Antalya",
        "yöntem": "Uygulama"
      },
      {
        "isim": "AVUSTURALYA KONSOLSLUĞU",
        "yer": "Yeniköy/İstanbul",
        "yöntem": "Proje + Uygulama"
      },
      {
        "isim": "EDS ENJEKSİYON A.Ş. FABRİKALARI",
        "yer": "Şekerpınar /İstanbul",
        "yöntem": "Uygulama"
      },
      {
        "isim": "BOSTANCI CAVİTPAŞA KÖŞKÜ",
        "yer": "Bostancı  / İstanbul",
        "yöntem": "Uygulama"
      }
     ]
  },
];
export default elektrik_referanslar
/*8*/