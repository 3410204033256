<template>
  <section id="contact" class="container">
    <div class="row py-5 my-5">
      <div class="col-sm-6 pr-2">
        <p class="h1">Gence Mühendislik</p>
        <p>İnşaat Elektrik Elektronik San. Tic. Ltd. Şti.</p>
        <p class="h4">Adres:</p>
        <p>Y.Dudullu Mah. Egemen Sok. No:6/A 34775 Ümraniye/İstanbul</p>
        <p class="h4">Telefon:</p>
        <a href="tel:+902165264853" class="mb-0">0(216) 526 48 53/54</a>
        <p class="h4">Fax:</p>
        <p>+90(216) 526 48 55</p>
        <p class="h4">E-Posta:</p>
        <p>info@gencemuhendislik.com.tr</p>
      </div>
      <div class="col-sm-6 pr-2">
        <p class="h4">Harita:</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.4487349753376!2d29.151835715374453!3d41.01543757930004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac89640b0eee9%3A0xf6df2df1db2da511!2sGence%20M%C3%BChendislik%20San.%20ve%20Tic.%20Ltd.%C5%9Eti.!5e0!3m2!1str!2str!4v1600259324588!5m2!1str!2str"
          width="100%"
          height="100%"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#contact{
    
    color: rgba($color: #000000, $alpha: .64);
    min-height: 80vh;
    .h1,.h4{
        color: rgba($color: #164098, $alpha: .87);
    }
}
</style>