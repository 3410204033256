/*8*/
const guvenlik_referanslar = [
  {
    baslik:"Otomotiv - Servis",
    datas:[
      {
        "isim": "VOLVO CAR TURKİYE",
        "yer": "Tepeüstü / İstanbul"
      },
      {
        "isim": "VOLVO TRUCK GENEL MÜDÜRLÜK",
        "yer": "Çakmak/ İstanbul"
      },
      {
        "isim": "VOLVO  TRUCK GOSB DEPO",
        "yer": "GOSB / Kocaeli"
      },
      {
        "isim": "RENAULT TRUCK  SERVİS",
        "yer": "Orhanlı / İstanbul"
      },
      {
        "isim": "VOLVO TRUCK SERVİS",
        "yer": "Hadımköy / İstanbul"
      },
      {
        "isim": "KİA MERKEZ SERVİS",
        "yer": "Kartal /İstanbul"
      },
      {
        "isim": "KİA GÜLAL OTO",
        "yer": "Çakmak/ İstanbul"
      },
      {
        "isim": "MERCEDES SERVİS HASES OTO",
        "yer": "Taşdelen/İstanbul"
      },
      {
        "isim": "MERCEDES BOYAHANE HASES OTO",
        "yer": "Taşdelen/İstanbul"
      },
      {
        "isim": "KOCASLANLAR OTOMAOTİV KURTKÖY SERVİS",
        "yer": "İstanbul"
      },
      {
        "isim": "KOCASLANLAR OTOMAOTİV HADIMKÖY  SERVİS",
        "yer": "İstanbul"
      }
     ]
  },
  {
    baslik:"Sağlık Kurumlarıi",
    datas:[
      {
        "isim": "UMRANİYE EĞİTİM ARAŞTIRMA HASTAHANESİ",
        "yer": "İstanbul"
      },
      {
        "isim": "ÜMRANİYE KADIN DOĞUM VE ÇOCUK HASTAHANESİ",
        "yer": "İstanbul"
      },
      {
        "isim": "KEZBAN ESAT UNAL SEMT POLİKLİNİĞİ",
        "yer": "İstanbul"
      },
      {
        "isim": "MUSTAFA KEMAL SEMT POLİKLİNİĞİ",
        "yer": "İstanbul"
      },
      {
        "isim": "GODOOR HOSPITAL ( 250 YATAKLI)",
        "yer": "Trablusgarp / Libya"
      },
      {
        "isim": "DOHUK HASTAHANESİ",
        "yer": "Kuzey Irak"
      },
      {
        "isim": "FİZİKMER FİZİK TEDAVİ MERKEZİ",
        "yer": "Kocaeli"
      },
      {
        "isim": "SBY SAĞLIK MEDİKAL LTD.ŞTİ",
        "yer": "İstanbul"
      },
      {
        "isim": "MGS GÜZELLİK SALONU GN.MRKZ.",
        "yer": "Kayışdağı / İstanbul"
      },
      {
        "isim": "MGS GÜZELLİK SALONU DEPO",
        "yer": "Kayışdağı / İstanbul"
      },
      {
        "isim": "ERDEM HASTANESİ",
        "yer": "Güneşli / İstanbul"
      },
      {
        "isim": "BİOMEGA MEDİKAL ÜRÜNLER",
        "yer": "Silivri / İstanbul"
      },
      {
        "isim": "FATİH VEREM SAVAŞ DİSPANSERİ",
        "yer": "Fatih / İstanbul"
      },
      {
        "isim": "MGS GÜZELLİK SALONU GN.MRKZ.",
        "yer": "Ataşehir / İstanbul"
      },
      {
        "isim": "ACAR KOZMETİK",
        "yer": "Mersin"
      },
      {
        "isim": "FARMASİ GENEL MÜDÜRLÜK  ",
        "yer": "Ömerli  /  İstanbul "
      }
     ]
  },
  {
    baslik:"Fabrika - Tesis",
    datas:[
      {
        "isim": "OYAK İNŞAAT ",
        "yer": "Kozyatağı / İstanbul"
      },
      {
        "isim": "HÖRMANN",
        "yer": "İstanbul"
      },
      {
        "isim": "GRACE YAPI KİMYASALLARI",
        "yer": "GOSB / Kocaeli"
      },
      {
        "isim": "EDS ENJEKSİYON ",
        "yer": "GOSB / Kocaeli"
      },
      {
        "isim": "ALRABEEA SPONGE AND MATTARESSES FACTORY",
        "yer": "Trablusgarp / Libya"
      },
      {
        "isim": "SMART SOLAR GROUP",
        "yer": "Maslak / İstanbul"
      },
      {
        "isim": "LIMAGRIAN TOHUM FABRIKASI",
        "yer": "Bursa"
      },
      {
        "isim": "AZERTOHUM FABRİKASI",
        "yer": "Yevlax / Azerbaycan"
      },
      {
        "isim": "DHT METAL ",
        "yer": "Bakü / Azerbaycan"
      },
      {
        "isim": "AZERYEM FABRİKASI",
        "yer": "Balaken / Azerbaycan"
      },
      {
        "isim": "DSİ SU DÜNYASI WATERWORLD",
        "yer": "Gözteple / İstanbul"
      },
      {
        "isim": "RE-CONSULT RÜZGAR İÇ VE DIŞ TİC.LTD.ŞTİ.",
        "yer": "Ankara"
      },
      {
        "isim": "GÜVEN MAKİNE ",
        "yer": "Organize Sanayi / Kayseri"
      },
      {
        "isim": "PALME MAKİNE TİC.LTD.ŞTİ.",
        "yer": "Y.Dudullu / İstanbul"
      },
      {
        "isim": "GÜLPANO TİC.LTD.ŞTİ.",
        "yer": "Ferhatpaşa / İstanbul"
      },
      {
        "isim": "ÜÇELLER KERESTE TİC.LTD.ŞTİ.",
        "yer": "Y.Dudullu / İstanbul"
      },
      {
        "isim": "BİLSAR TEKSTİL A.Ş.",
        "yer": "Beyoğlu / İstanbul"
      },
      {
        "isim": "OSEM PANO LTD.ŞTİ",
        "yer": "Y.Dudullu / İstanbul"
      }
     ]
  },
  {
    baslik:"Eğitim Kurumları",
    datas:[
      {
        "isim": "TENZİLE ERDOĞAN LİSESİ",
        "yer": "İstanbul"
      },
      {
        "isim": "WALL STREET INSTITUTE (PEN ACADEMY)",
        "yer": "Kocaeli"
      },
      {
        "isim": "WALL STREET INSTITUTE (PEN ACADEMY)",
        "yer": "Ankara"
      },
      {
        "isim": "WALL STREET INSTITUTE (PEN ACADEMY)",
        "yer": "Kocaeli"
      },
      {
        "isim": "T.C. BİLECİK ÜNİVERSİTESİ",
        "yer": "Bilecik"
      },
      {
        "isim": "T.C. BİLECİK ÜNİVERSİTESİ ÖSYM MERKEZİ",
        "yer": "Bilecik"
      },
      {
        "isim": "T.C  SAKARYA ÜNİVERSİTESİ",
        "yer": "Sakarya"
      }
     ]
  },
  {
    baslik:"Genel",
    datas:[
      {
        "isim": "LEXMARK GENEL MÜDÜRLÜK ",
        "yer": "Palladium Tower/ İstanbul"
      },
      {
        "isim": "SENATUS OTEL ",
        "yer": "Sultanahmet / İstanbul"
      },
      {
        "isim": "SZUTEST GENEL MÜDÜRLÜK",
        "yer": "Ataşehir / İstanbul"
      },
      {
        "isim": "PRF.CELAL ŞENGÖR KÜTÜPHANESİ",
        "yer": "Anadou Hisarı / İstanbul"
      },
      {
        "isim": "CPP TURKEY CALL CENTER",
        "yer": "Nida Kule / İstanbul"
      },
      {
        "isim": "PALME MAKİNE TİC.LTD.ŞTİ.",
        "yer": "Y.Dudullu / İstanbul"
      },
      {
        "isim": "CAVİT ÇAĞLAR MALİKHANE",
        "yer": "Emirgan / İstanbul"
      },
      {
        "isim": "COMMERICIAL UNION SİGORTA GN MÜDÜRLÜK",
        "yer": "Çamlıca / İstanbul"
      },
      {
        "isim": "M.VARGI MALİKHANE",
        "yer": "İstanbul"
      },
      {
        "isim": "ÇAĞLAYAN ADLİYE SARAYI",
        "yer": "İstanbul"
      },
      {
        "isim": "DSİ 14.BÖLGE MÜDÜRLÜĞÜ",
        "yer": "Göztepe / İstanbul"
      },
      {
        "isim": "HAVA İŞ SENDİKA BİNASI ",
        "yer": "Bakırköy / İstanbul"
      },
      {
        "isim": "VODATECH CALL CENTER",
        "yer": "Kayışdağı / İstanbul"
      },
      {
        "isim": "İHA BİLGİ İŞLEM MÜDÜRLÜĞÜ",
        "yer": "Yenibosna / İstanbul"
      },
      {
        "isim": "FABULİST AVM",
        "yer": "Sancaktepe /İstanbul"
      },
      {
        "isim": "BRITISH COUNCIL",
        "yer": "Zincirli Kuyu / İstanbul"
      },
      {
        "isim": "MIRA TRANSPORT ANTREPO",
        "yer": "Avcılar / İstanbul"
      },
      {
        "isim": "STAR INFORMATION SERVICE  LTD.",
        "yer": "Ataşehir / İstanbul"
      },
      {
        "isim": "ATLASJET GENEL MÜDÜRLÜK",
        "yer": "Fulya  / İstanbul"
      },
      {
        "isim": "ATLASJET ANTALYA ",
        "yer": "Antalya "
      },
      {
        "isim": "ALGE ELEKTRİK LTD.ŞTİ",
        "yer": "Maltepe / İstanbul"
      },
      {
        "isim": "KUTLU TEKSTİL  LTD.ŞTİ",
        "yer": "Ünye / Ordu"
      },
      {
        "isim": "AKSOY DENİZCİLİK LTD.ŞTİ.",
        "yer": "Çengelköy / İstanbul"
      },
      {
        "isim": "TEM FİLM STÜDYOLARI",
        "yer": "Güneşli / İstanbul"
      },
      {
        "isim": "BALCIOĞLU SELCUK AKMAN KEKİ AVUKATLIK",
        "yer": "Maslak / İstanbul"
      },
      {
        "isim": "ARTI  ELEKTRİK LTD.ŞTİ.",
        "yer": "Tuzla / İstanbul"
      },
      {
        "isim": "KVK TEKNİK SERVİS DEPO",
        "yer": "Ataşehir / İstanbul"
      },
      {
        "isim": "KVK GENEL MÜDÜRLÜK",
        "yer": "Kozyatağı/İstanbul"
      },
      {
        "isim": "MV HOLDİNG",
        "yer": "Kozyatağı / İstanbul"
      },
      {
        "isim": "NET SERVİS DESTEK HİZMETLERİ",
        "yer": "Altunizade / İstanbul"
      },
      {
        "isim": "SANMAR DENİZCİLİK",
        "yer": "Tuzla / İstanbul"
      },
      {
        "isim": "İBB / SPOR A.Ş.",
        "yer": "Aksaray / İstanbul"
      },
      {
        "isim": "ÇALIŞKAN TURİZM VE TAŞIMACILIK A.Ş.",
        "yer": "Ataşehir/İstanbul"
      },
      {
        "isim": "İBB / KENTSEL DÖNÜŞÜM MÜD.",
        "yer": "Fatih/İstanbul"
      },
      {
        "isim": "VASTECH İLETİŞİM / 11833",
        "yer": "Kozyatağı/İstanbul"
      },
      {
        "isim": "EFE İLETİŞİM",
        "yer": "Ortaköy/İstanbul"
      },
      {
        "isim": "YKK HUKUK BÜROSU",
        "yer": "Astoria AVM / İstanbul"
      },
      {
        "isim": "CIRCUTOR TURKEY ECT MÜHENDİSLİK",
        "yer": "Y.Dudullu / İstanbul"
      },
      {
        "isim": "DHT HOLDİNG",
        "yer": "Kozyatağı / İstanbul"
      },
      {
        "isim": "TCHIBO GENEL MÜDÜRLÜK",
        "yer": "Kozyatağı / İstanbul"
      },
      {
        "isim": "SULTAN AHMET KÖFTECİSİ GN.MÜDÜRLÜK",
        "yer": "Y.Dudullu / İstanbul"
      },
      {
        "isim": "KAPİTAL YATIRIM",
        "yer": "Uso Center / İstanbul"
      },
      {
        "isim": "ENSER A.Ş.",
        "yer": "İstanbul"
      },
      {
        "isim": "CULLAS DIŞ TİC.A.Ş.",
        "yer": "İstanbul"
      },
      {
        "isim": "OPMAR OPTİK  GENEL MÜDÜRLÜK",
        "yer": "Okmeydanı / İstanbul"
      },
      {
        "isim": "TOCA MİMARLIK LTD.ŞTİ.",
        "yer": "Kızıltoprak / İstanbul"
      },
      {
        "isim": "MAPA GENEL MÜDÜRLÜK",
        "yer": "Sanayi  Mahallesi / İstanbul"
      },
      {
        "isim": "SEFEROĞLU İNŞAAT TİC.LTD.ŞTİ.",
        "yer": "İstanbul"
      },
      {
        "isim": "MARKS & SPENCER GENEL MÜDÜRLÜK",
        "yer": "Kozyatağı / İstanbul"
      },
      {
        "isim": "AKÇA İNŞAAT",
        "yer": "Çekmeköy / İstanbul"
      }
     ]
  },
  {
    baslik:"Mağazacılık",
    datas:[
      {
        "isim": "ACAR GİYİM ",
        "yer": "İzmir"
      },
      {
        "isim": "MAPA",
        "yer": "Nişantaşı"
      },
      {
        "isim": "MAPA",
        "yer": "Bebek / İstanbul"
      },
      {
        "isim": "MAPA",
        "yer": "İstinyepark / İstanbul"
      },
      {
        "isim": "MAPA",
        "yer": "Florya Aquapark / İstanbul"
      },
      {
        "isim": "MAPA",
        "yer": "Suadiye / İstanbul"
      },
      {
        "isim": "MAPA",
        "yer": "Zorulu AVM / İstanbul"
      },
      {
        "isim": "MAPA",
        "yer": "Akmerkez AVM / İstanbul"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "İstinyepark AVM / stanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Natilius AVM / İstanbul"
      },
      {
        "isim": "MASIMO DUTTI",
        "yer": "Godion AVM / Ankara"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Gordion Avm /Ankara"
      },
      {
        "isim": "STRDIVARIUS",
        "yer": "Godion AVM / Ankara"
      },
      {
        "isim": "OYSHO",
        "yer": "Godion AVM / Ankara"
      },
      {
        "isim": "BERSHKA",
        "yer": "Godion AVM / Ankara"
      },
      {
        "isim": "ZARA HOME",
        "yer": "Godion AVM / Ankara"
      },
      {
        "isim": "ZARA",
        "yer": "Godion AVM / Ankara"
      },
      {
        "isim": "ZARA",
        "yer": "Towncenter / İstanbul"
      },
      {
        "isim": "AYKUT TURAN MİMARLIK DEPO",
        "yer": "Kavacık / İstanbul"
      },
      {
        "isim": "BARTUR TRANS",
        "yer": "Ferhatpaşa / İstanbul"
      },
      {
        "isim": "ETS PANO LTD.ŞTİ.",
        "yer": "Sultanbeyli / İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Suadiye / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Bağdat Cd. / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "M1 AVM /Adana"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Forum AVM / Mersin"
      },
      {
        "isim": "BERSHKA",
        "yer": "Forum AVM / Mersin"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Forum AVM / Mersin"
      },
      {
        "isim": "OYSHO",
        "yer": "Forum AVM / Mersin"
      },
      {
        "isim": "ZARA",
        "yer": "Forum AVM / Mersin"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Espark AVM / Eskişehir"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Espark AVM / Eskişehir"
      },
      {
        "isim": "BERSHKA",
        "yer": "Espark AVM / Eskişehir"
      },
      {
        "isim": "ZARA",
        "yer": "Espark AVM / Eskişehir"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Forum Bornova AVM / İzmir"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Forum Bornova AVM / İzmir"
      },
      {
        "isim": "OYSHO",
        "yer": "Forum Bornova AVM / İzmir"
      },
      {
        "isim": "BERSHKA",
        "yer": "Forum Bornova AVM / İzmir"
      },
      {
        "isim": "ZARA HOME",
        "yer": "Forum Bornova AVM / İzmir"
      },
      {
        "isim": "ZARA",
        "yer": "Forum Bornova AVM / İzmir"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Kentmeydanı AVM / Bursa"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Kentmeydanı AVM / Bursa"
      },
      {
        "isim": "BERSHKA",
        "yer": "Kentmeydanı AVM / Bursa"
      },
      {
        "isim": "ZARA",
        "yer": "Kentmeydanı AVM / Bursa"
      },
      {
        "isim": "OYSHO",
        "yer": "Kentmeydanı AVM / Bursa"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "Korupark AVM / Bursa"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Korupark AVM / Bursa"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Korupark AVM / Bursa"
      },
      {
        "isim": "ZARAHOME",
        "yer": "Korupark AVM / Bursa"
      },
      {
        "isim": "BERSHKA",
        "yer": "Korupark AVM / Bursa"
      },
      {
        "isim": "ZARA",
        "yer": "Korupark AVM / Bursa"
      },
      {
        "isim": "BERSHKA",
        "yer": "Metrocity AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Metrocity AVM / İstanbul"
      },
      {
        "isim": "BERHSKA",
        "yer": "Taksim / İstanbul"
      },
      {
        "isim": "UTERQUE",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "UTERQUE",
        "yer": "Torium AVM / İstanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Torium AVM / İstanbul"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Torium AVM / İstanbul"
      },
      {
        "isim": "OYSHO",
        "yer": "Torium AVM / İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Torium AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Torium AVM / İstanbul"
      },
      {
        "isim": "OYSHO",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "ZARA HOME",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "İstinyepark AVM / stanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "İstinyepark AVM / stanbul"
      },
      {
        "isim": "OYSHO",
        "yer": "İstinyepark AVM / stanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "İstinyepark AVM / stanbul"
      },
      {
        "isim": "ZARA",
        "yer": "İstinyepark AVM / stanbul"
      },
      {
        "isim": "OYSHO",
        "yer": "Historia AVM / İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Historia AVM / İstanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Historia AVM / İstanbul"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Historia AVM / İstanbul"
      },
      {
        "isim": "ZARA HOME",
        "yer": "Historia AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Historia AVM / İstanbul"
      },
      {
        "isim": "ZARA KIDS",
        "yer": "Akmerkez AVM / İstanbul"
      },
      {
        "isim": "UTERQUE",
        "yer": "Akmerkez AVM / İstanbul"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "Akmerkez AVM / İstanbul"
      },
      {
        "isim": "ZARA HOME",
        "yer": "Akmerkez AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Akmerkez AVM / İstanbul"
      },
      {
        "isim": "OYSHO",
        "yer": "Palladium AVM /İstanbul"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "Palladium AVM /İstanbul"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Palladium AVM /İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Palladium AVM /İstanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Palladium AVM /İstanbul"
      },
      {
        "isim": "ZARAHOME",
        "yer": "Palladium AVM /İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Palladium AVM /İstanbul"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "Capacity AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Capacity AVM / İstanbul"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Capacity AVM / İstanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Capacity AVM / İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Capacity AVM / İstanbul"
      },
      {
        "isim": "İLETİŞİM ELEKTRİK LTD.ŞTİ.",
        "yer": "Ümraniye / İstanbul"
      },
      {
        "isim": "VODATECH CALL CENTER",
        "yer": "Gebze / Kocaeli"
      },
      {
        "isim": "OYSHO",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "ZARAHOME",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "ZARA",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "PULL AND BEAR",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "BERSHKA",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "EMSA ECA SİSTEM ODASI",
        "yer": "Esenşehir / İstanbul"
      },
      {
        "isim": "BOYNER BEAUTE MAĞAZSI",
        "yer": "Galeria AVM / İstanbul"
      },
      {
        "isim": "MASSIMO DUTTI",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "MARKS & SPENCER",
        "yer": "Bodrum"
      },
      {
        "isim": "MARKS & SPENCER",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "DARTY",
        "yer": "Profilo AVM / İstanbul"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "carousel AVM / İstanbul"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Akmerkez AVM / İstanbul"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Maltepe / İstanbul"
      },
      {
        "isim": "YKM",
        "yer": "Denizli"
      },
      {
        "isim": "BOYNER MAĞAZACILIK",
        "yer": "Capitol AVM / İstanbul"
      },
      {
        "isim": "MARKS & SPENCER",
        "yer": "Flyin AVM / İstanbul"
      },
      {
        "isim": "MARKS & SPENCER",
        "yer": "Gima AVM / Antalya"
      },
      {
        "isim": "ONUR TEKSTİL LTD.ŞTİ.",
        "yer": "İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Cevahir AVM / İstanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Ankamoll AVM / Ankara"
      },
      {
        "isim": "BİM MAĞAZASI",
        "yer": "Elmadağ / Ankara"
      },
      {
        "isim": "PEXIMET CAFE",
        "yer": "Bağdat Cd. / İstanbul"
      },
      {
        "isim": "PULL & BEARS",
        "yer": "Forum AVM / İstanbul"
      },
      {
        "isim": "STRADIVARIUS",
        "yer": "Forum AVM / İstanbul"
      },
      {
        "isim": "BERSHKA",
        "yer": "Forum AVM / İstanbul"
      },
      {
        "isim": "OYSHO",
        "yer": "Forum AVM / İstanbul"
      },
      {
        "isim": "ZARA",
        "yer": "Forum AVM / İstanbul"
      },
      {
        "isim": "BERSHKA DEPO",
        "yer": "Metrocity AVM / İstanbul"
      },
      {
        "isim": "ÜÇER ELEKTRİK LTD.ŞTİ",
        "yer": "Kağıthane / İstanbul"
      },
      {
        "isim": "OPMAR OPTİK",
        "yer": "Antalya"
      },
      {
        "isim": "OPMAR OPTİK",
        "yer": "Ankara"
      },
      {
        "isim": "OPMAR OPTİK",
        "yer": "Sefaköy / İstanbul"
      },
      {
        "isim": "OPMAR OPTİK",
        "yer": "İstanbul"
      }
     ]
  },
  {
    baslik:"Market",
    datas:[
      {
        "isim": "BİM MAĞAZASI",
        "yer": "Eskişehir"
      },
      {
        "isim": "BİM MAĞAZASI İNCEYOL",
        "yer": "İnceyol / İstanbul"
      },
      {
        "isim": "BİM MAĞAZASI KESTEL",
        "yer": "Kestel/ Bursa"
      },
      {
        "isim": "BiM MAĞAZASI MUSTAFA KEMAL PAŞA",
        "yer": "M.Kemal Paşa / Bursa"
      },
      {
        "isim": "BİM MAĞAZASI ADATEPE",
        "yer": "Adatepe / İstanbul"
      },
      {
        "isim": "BİM MAĞAZASI ESENKENT",
        "yer": "Esenket /İstanbul"
      },
      {
        "isim": "BİM MAĞAZASI ZÜMRÜTEVLER",
        "yer": "Zümrütevler / İstanbul"
      },
      {
        "isim": "BİM MAĞAZASI MALTEPE",
        "yer": "Maltepe / İstanbul"
      },
      {
        "isim": "BİM MAĞAZASI KARTAL",
        "yer": "Kartal /İstanbul"
      }
     ]
  },
  {
    baslik:"Mağazacılık Proje ve Kontrol",
    datas:[
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Afium AVM / Afyon"
      },
      {
        "isim": "ZIYLAN INSTREET  MAĞAZACILIK ",
        "yer": "Zafer Plaza / Bursa"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Mall Of Antalya / Antalya"
      },
      {
        "isim": "ZIYLAN KUMSMALL FLO MAĞAZACILIK",
        "yer": "Kayseri "
      },
      {
        "isim": "ZIYLAN  FLO MAĞAZACILIK  KEPEZ",
        "yer": "Kepez /Antalya"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Kayseri "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Brandium AVM /İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Erdemli Novada / Mersin"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Menemen / İzmir"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Noracity / Aksaray"
      },
      {
        "isim": "ZIYLAN INSTREET  MAĞAZACILIK ",
        "yer": "Fatih  / İstanbul"
      },
      {
        "isim": "ZIYLAN INSTREET  MAĞAZACILIK ",
        "yer": "M1  AVM / Adana"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Zonguldak"
      },
      {
        "isim": "ZIYLAN NINEWEST MAĞAZACILIK ",
        "yer": "Forum AVM / Adana"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Angora /Antalya "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Sümerpark/Denizli"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Kızılay / Ankara"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Çubuk/Ankara"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Etimesgut / Ankara"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Erciş/Van"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Batmanpark/Batman"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Urfa Piazza/Urfa"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "39Burda /Kırklarleri "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "67 Burda/Zonguldak "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Canpark/İstanbul"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Meydavn AVM/İstanbul"
      },
      {
        "isim": "GENCALLAR MAĞAZACILIK",
        "yer": "Atlaspark /İstanbul"
      },
      {
        "isim": "GENCALLAR MAĞAZACILIK",
        "yer": "39Burda /Kırklarleri "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Watergarden / İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Ümraniye Meydan AVM"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Starcity/İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Novada AVM/Tokat "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Sivas Meydan "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Point AVM/İzmir "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Pashadoor/İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Optimum AVM/İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Tarsu AVM /Mersin "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Mezitli Cadde/ Mersin "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Manisa Cadde"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Kars  Meydan "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Westpark İzmir "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Torbalı İzmir "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "İnegöl Avm "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Historia AVM /İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Dörtyol Hatay  "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Haliç AVM /İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Giresun Cadde "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Elazığ Cadde "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Park23/Elazığ "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "FSM cd/ Bursa"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Brandium AVM /İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Midtown/Bodrum "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Bergama İzmir "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Bakırköy İstanbul"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Avlu AVM /İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Armonipak /İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Kartaltepe AVM Ankara "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "212AVM İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "10Burda AVM Balıkesir"
      },
      {
        "isim": "ZIYLAN INSTREET MAĞAZACILIK ",
        "yer": "Forum Sport / İstanbul"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Sedirvan AVM  / Sakarya"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Şimal AVM / Rize"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Neo AVM  / Eskişehir"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Batmanpark AVM / Batman"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Nevçarşı / İstanbul"
      },
      {
        "isim": "ZIYLAN INSTREET  MAĞAZACILIK ",
        "yer": "Mardian AVM / Mardin"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Mardian AVM / Mardin"
      },
      {
        "isim": "ZIYLAN INSTREET MAĞAZACILIK ",
        "yer": "Forum AVM / Mersin"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Novada AVM/  Mersin"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Sakıpağa  Outlet/  İzmir"
      },
      {
        "isim": "ZIYLAN INSTEET  MAĞAZACILIK ",
        "yer": "Emar AVM / İstanbul"
      },
      {
        "isim": "ZIYLAN NINEWEST MAĞAZACILIK ",
        "yer": "Korupark AVM  / Bursa"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Susanoğlu AVM / Mersin"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Cizre"
      },
      {
        "isim": "ZIYLAN INSTREET MAĞAZACILIK ",
        "yer": "Bulvar AVM / Samsun"
      },
      {
        "isim": "ZIYLAN INSTREET MAĞAZACILIK ",
        "yer": "M1  AVM / Adana"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Zonguldak"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Historia AVM /İstanbul "
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Van"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Viaport AVM/ İstanbul"
      },
      {
        "isim": "ZIYLAN INSTREET MAĞAZACILIK ",
        "yer": "Bayrampaşa Axis / İstanbul"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Buca  /İstanbul"
      },
      {
        "isim": "ZIYLAN INSTREET MAĞAZACILIK ",
        "yer": "Novada AVM / Akhisar"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Güngören Park AVM/ İstanbul"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Avenue AVM /  İstanbul"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "Maçkapark /Bursa"
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "Hilltown/istanbul"
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "Vadiistanbul/İst"
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "MaltepePİazza /İST"
      },
      {
        "isim": "BİG DÜRÜM",
        "yer": "Capitol / İstanbul"
      },
      {
        "isim": "DOROMCO",
        "yer": "Vadiistanbul/İst"
      },
      {
        "isim": "DOROMCO",
        "yer": "Malloff /İstanbul"
      },
      {
        "isim": "BOYNER MAĞAZACILIK",
        "yer": "Capitol/İstanbul"
      },
      {
        "isim": "BOYNER BEAUTE",
        "yer": "Galeria / İstanbul"
      },
      {
        "isim": "YKM MAĞAZASI ( 10 katlı)",
        "yer": "Denizli"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "İstinyepark / İstanbul"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Suadiye / İstanbul"
      },
      {
        "isim": "MARKS & SPENCER GENEL MÜDÜRLÜĞÜ",
        "yer": "Kozyatağı/İstanbul"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Capitol/ İstanbul"
      },
      {
        "isim": "MARKS & SPENCER ÇOCUK MAĞAZASI",
        "yer": "Flyİnn/İstanbul"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Gima /Antalya"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Akmerkez /İstanbul"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Flyİnn/İstanbul"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "AS Plaza/Bursa"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Nişantaşı / İstanbul"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Oasis / Bodrum"
      },
      {
        "isim": "MARKS & SPENCER MAĞAZASI",
        "yer": "Kozyatağı GM / İstanbul"
      },
      {
        "isim": "THCIBO GENEL MÜDÜRLÜK OFİSİ",
        "yer": "Bay Plaza / Kozyatağı"
      },
      {
        "isim": "THCIBO MAĞAZASI",
        "yer": "Capitol / İstanbul"
      },
      {
        "isim": "THCIBO MAĞAZASI",
        "yer": "Carrefoursa/ Ümraniye"
      },
      {
        "isim": "MOR DEKORASYON",
        "yer": "Kavacık/İstanbul"
      },
      {
        "isim": "ZARA TEKSTİL GENEL MÜDÜRLÜK OFİSİ.",
        "yer": "İşkule / İstanbul"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Denizli"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Trabzon"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Palladium/ İstanbul"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Agora/İzmir"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Tekirdağ"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Pendik/istanbul"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Güneşli / İstanbul"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Sefaköy/ İstanbul"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Acity / Ankara"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Denizli"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "İstinyepark / İstanbul"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Güneşli / İstanbul"
      },
      {
        "isim": "OPMAR OPTİK MAĞAZASI",
        "yer": "Ada Center/ Sakarya"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Bağcılar/İstanbul"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Sefaköy/İstanbul"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Taksim/İstanbul"
      },
      {
        "isim": "TURKCELL ABONE MERKEZİ",
        "yer": "Topkapı/İstanbul"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Carrefoursa/Maltepe"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Akmerkez/İstanbul"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Carusel/İstanbul"
      },
      {
        "isim": "DERİMOD MAĞAZASI",
        "yer": "Cevahir İŞ Merkezi"
      },
      {
        "isim": "BATA MAĞAZASI",
        "yer": "Cevahir İŞ Merkezi"
      },
      {
        "isim": "KVK TURKCELL SHOP",
        "yer": "Cevahir İş Merkezi"
      },
      {
        "isim": "KVK TURKCELL SHOP",
        "yer": "Akvaryum İş Merkezi"
      },
      {
        "isim": "ZIYLAN FLO MAĞAZACILIK ",
        "yer": "39Burda /Kırklarleri "
      },
      {
        "isim": "GENCALLAR MAĞAZACILIK",
        "yer": "MNG  AVM / Erzurum "
      }
     ]
  }
];
export default guvenlik_referanslar;